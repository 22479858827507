import { DEFAULT_WEIGHT_UOM, QUOTED_PRICED_BY } from '@/modules/shared/constants/';
import { priceFormatter } from '@/utils/';
import { displayPrice } from './helper';

const formatPrice = (currency, price) => priceFormatter(currency, displayPrice(price));

export const priceFn = ({ max_price, min_price, max_weight, min_weight }, currencySymbol) => {
  if (max_price === 0) {
    return `${formatPrice(currencySymbol, max_price / max_weight)} /${DEFAULT_WEIGHT_UOM}`;
  } else if (min_price !== max_price || min_weight !== max_weight) {
    const [lowestPrice, highestPrice] = [min_price / min_weight, max_price / max_weight].sort(
      (a, b) => a - b
    );
    return `${formatPrice(currencySymbol, lowestPrice)} - ${formatPrice(
      currencySymbol,
      highestPrice
    )} /${DEFAULT_WEIGHT_UOM}`;
  } else {
    return `${formatPrice(currencySymbol, min_price / min_weight)} /${DEFAULT_WEIGHT_UOM}`;
  }
};

export const setTotalPricePerUomFn = ({ skusData }, currencySymbol, isCalculateByWeight, fromText) => {
  let text = '';

  if (skusData && skusData.length > 0) {
    const arePricesEqual = skusData.every(
      (item) => item.price / item.weight === skusData[0].price / skusData[0].weight
    );
    const areWeightEqual = skusData.every((item) => item.weight === skusData[0].weight);
    const lowestWeightItem = skusData.reduce((prev, current) =>
      prev.weight < current.weight ? prev : current
    );
    const highestWeightItem = skusData.reduce((prev, current) =>
      prev.weight > current.weight ? prev : current
    );

    let lowestRatio = Infinity;
    let lowestRatioItem = null;

    // Iterate through the array to find the lowest price-to-weight ratio
    skusData.forEach((item) => {
      const ratio =
        item.price /
        (item.is_order_by_weight || item.is_catch_weight || item.priced_by === QUOTED_PRICED_BY.WEIGHT
          ? item.weight
          : item.unit_amount);
      if (ratio < lowestRatio) {
        lowestRatio = ratio;
        lowestRatioItem = item;
      }
    });

    if (arePricesEqual) {
      if (isCalculateByWeight)
        text = `${formatPrice(
          currencySymbol,
          skusData[0].price / skusData[0].weight
        )} /${DEFAULT_WEIGHT_UOM}`;
      else
        text = !areWeightEqual
          ? `${formatPrice(
              currencySymbol,
              lowestWeightItem.price /
                (highestWeightItem.is_order_by_weight ||
                highestWeightItem.is_catch_weight ||
                highestWeightItem.priced_by === QUOTED_PRICED_BY.WEIGHT
                  ? highestWeightItem.weight
                  : highestWeightItem.unit_amount)
            )} /${lowestWeightItem.uom}`
          : `${formatPrice(
              currencySymbol,
              skusData[0].price /
                (skusData[0].is_order_by_weight ||
                skusData[0].is_catch_weight ||
                skusData[0].priced_by === QUOTED_PRICED_BY.WEIGHT
                  ? skusData[0].weight
                  : skusData[0].unit_amount)
            )} /${skusData[0].uom}`;
    } else {
      if (isCalculateByWeight)
        text = `${fromText} ${formatPrice(
          currencySymbol,
          lowestRatioItem.price / lowestRatioItem.weight
        )} /${DEFAULT_WEIGHT_UOM}`;
      else
        text =
          lowestRatioItem.is_order_by_weight ||
          lowestRatioItem.is_catch_weight ||
          lowestRatioItem.priced_by === QUOTED_PRICED_BY.OOM
            ? `${fromText} ${formatPrice(
                currencySymbol,
                lowestRatioItem.price /
                  (lowestRatioItem.is_order_by_weight ||
                  lowestRatioItem.is_catch_weight ||
                  lowestRatioItem.priced_by === QUOTED_PRICED_BY.WEIGHT
                    ? lowestRatioItem.weight
                    : lowestRatioItem.unit_amount)
              )} /${lowestRatioItem.uom}`
            : `${fromText} ${formatPrice(
                currencySymbol,
                lowestRatioItem.price / lowestRatioItem.weight
              )} /${DEFAULT_WEIGHT_UOM}`;
    }
  }
  return `${text}`;
};

export const setWeightFn = ({ skusData }) => {
  let text = '';
  let weights = [];
  let otherUnits = [];

  if (skusData && skusData.length > 0) {
    let uniqueUnitsItems = [...new Set(skusData.map((d) => d.unit_amount))].sort((a, b) => a - b);
    let uniqueWeightItems = [...new Set(skusData.map((d) => d.weight))].sort((a, b) => a - b);
    const allUomSameAndPricedByOom = skusData.every(
      (d) => d.uom === skusData[0].uom && d.priced_by !== QUOTED_PRICED_BY.WEIGHT
    );
    const allItemsPricedByWeight = skusData.every((d) => d.priced_by === QUOTED_PRICED_BY.WEIGHT);
    if (allUomSameAndPricedByOom) {
      text = uniqueUnitsItems.join('/ ') + ` ${skusData[0].uom}`;
    } else if (allItemsPricedByWeight) {
      text = uniqueWeightItems.join('/ ') + ` ${DEFAULT_WEIGHT_UOM}`;
    } else {
      skusData.forEach((d) => {
        if (d.priced_by === QUOTED_PRICED_BY.WEIGHT || d.uom === DEFAULT_WEIGHT_UOM) {
          const existingWeight = weights.find((weight) => weight.value === d.weight);
          if (!existingWeight) {
            weights.push({ value: d.weight, uom: DEFAULT_WEIGHT_UOM });
          }
        } else if (!otherUnits.some((unit) => unit.unit === d.unit_amount && unit.uom === d.uom)) {
          otherUnits.push({
            unit: d.unit_amount,
            uom: d.uom
          });
        }
      });

      // Sort and deduplicate weights
      weights = [...new Set(weights.sort((a, b) => a.value - b.value))];

      // Combine weights with "KG" and add uom at the end if uom is not empty
      let unitsWithUom = weights.map((weight) => `${weight.value} ${weight.uom}`);
      unitsWithUom = unitsWithUom.concat(
        otherUnits
          .sort((a, b) => (a.unit_amount !== b.unit_amount ? a.unit_amount - b.unit_amount : 0))
          .map((unit) => `${unit.unit} ${unit.uom}`)
      );

      // Combine the units into the final text
      text = unitsWithUom.join('/ ');
    }
  }
  return `${text}`;
};

export const weightFn = ({ min_weight, max_weight }) => {
  let text = '';
  if (min_weight != max_weight) text = `${min_weight} - ${max_weight}`;
  else text = min_weight;
  return `${text} ${DEFAULT_WEIGHT_UOM}`;
};

export const priceDiscountFn = (
  { max_price, min_price, display_max_price, display_min_price },
  currencySymbol
) => {
  if (max_price === 0) {
    return display_max_price ? display_max_price : formatPrice(currencySymbol, max_price);
  } else if (min_price !== max_price) {
    return `${display_min_price ? display_min_price : formatPrice(currencySymbol, min_price)} - ${
      display_max_price ? display_max_price : formatPrice(currencySymbol, max_price)
    }`;
  } else {
    return display_min_price ? display_min_price : formatPrice(currencySymbol, min_price);
  }
};

export const priceDiscountUomFn = (
  { skusData, max_price, min_price, display_max_price, display_min_price },
  currencySymbol
) => {
  let text = '';

  if (skusData && skusData.length > 0) {
    let allPricedByWeight = skusData.every((sku) => sku.priced_by === QUOTED_PRICED_BY.WEIGHT);
    let allCatchWeight = skusData.every((sku) => sku.is_catch_weight);
    let allOrderByWeight = skusData.every((sku) => sku.is_order_by_weight);
    let isCalculateByWeight = allPricedByWeight || allCatchWeight || allOrderByWeight;
    let pricesPerUnit = skusData.map((sku) => sku.price / sku.unit_amount);

    // Find the lowest and highest prices
    let lowestPrice = Math.min(...pricesPerUnit);
    let highestPrice = Math.max(...pricesPerUnit);

    if (!isCalculateByWeight) {
      if (skusData.length === 1 || lowestPrice === highestPrice) {
        text = `${formatPrice(currencySymbol, skusData[0].price)}`;
      } else {
        text = `${display_min_price ? display_min_price : formatPrice(currencySymbol, min_price)} - ${
          display_max_price ? display_max_price : formatPrice(currencySymbol, max_price)
        }`;
      }
    } else {
      if (skusData.length === 1 || lowestPrice === highestPrice) {
        text = `${formatPrice(currencySymbol, skusData[0].price)}`;
      } else if (max_price === 0) {
        text = display_max_price ? display_max_price : formatPrice(currencySymbol, max_price);
      } else if (min_price !== max_price) {
        text = `${display_min_price ? display_min_price : formatPrice(currencySymbol, min_price)} - ${
          display_max_price ? display_max_price : formatPrice(currencySymbol, max_price)
        }`;
      } else {
        text = display_min_price ? display_min_price : formatPrice(currencySymbol, min_price);
      }
    }
  }
  return `${text}`;
};

export const marketPriceFn = ({ min_market_price, max_market_price }, currencySymbol) => {
  if (min_market_price == max_market_price) {
    return formatPrice(currencySymbol, min_market_price);
  } else {
    return `${formatPrice(currencySymbol, min_market_price)} - ${formatPrice(
      currencySymbol,
      max_market_price
    )}`;
  }
};

export const discountFn = ({ min_market_price, max_market_price, min_price, max_price }) => {
  let minDiscount = 0;
  let maxDiscount = 0;
  if (min_market_price > min_price) {
    minDiscount = ((min_market_price - min_price) / min_market_price) * 100;
  }

  if (max_market_price > max_price) {
    maxDiscount = ((max_market_price - max_price) / max_market_price) * 100;
  }

  if (min_market_price == max_market_price) {
    return `${minDiscount.toFixed(2)}%`;
  }

  return `${minDiscount.toFixed(0)}% - ${maxDiscount.toFixed(0)}%`;
};

export const hasDiscountFn = ({ min_market_price, max_market_price, min_price, max_price }) => {
  let minDiscount = 0;
  let maxDiscount = 0;
  if (min_market_price > min_price) {
    minDiscount = ((min_market_price - min_price) / min_market_price) * 100;
  }

  if (max_market_price > max_price) {
    maxDiscount = ((max_market_price - max_price) / max_market_price) * 100;
  }

  return !!(minDiscount || maxDiscount);
};
