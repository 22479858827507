<template>
  <div>
    <div class="image">
      <ion-img
        :class="isHalal ? 'product-image' : 'product-image-no-halal mt-2'"
        :src="imageSrc"
        @ionError="errorLoadImage"
        @ionImgDidLoad="imageDidLoaded"
      >
      </ion-img>
    </div>
    <div v-if="isOos" class="image-off-oss"></div>
    <div v-if="isOos" class="blend-img"></div>
    <div v-if="isOos" class="centered-oos-label">
      <p class="oos">{{ $t('out_of_stock') }}</p>
    </div>
  </div>
</template>
<script>
import { IMAGE_PATH } from '@/modules/sale/constants/index';
import { DEFAULT_NO_IMAGE_FOR_PRODUCT } from '@/services/shared/helper/constants';
import { getImage } from '@/utils/';
import debounce from 'lodash.debounce';
import { defineComponent, onUnmounted, ref } from 'vue';

export default defineComponent({
  name: 'TreedotsImage',
  props: {
    image: {
      type: String,
      default: ``
    },
    type: {
      type: String,
      default: ``
    },
    cssClass: {
      type: String,
      default: ``
    },
    isHalal: {
      type: Boolean,
      default: false
    },
    isOos: {
      type: Boolean,
      default: false
    }
  },
  emits: ['image-setted', 'set-oos', 'errorLoadImage'],
  setup(_, { emit }) {
    const loaded = ref(false);
    const imageSrc = ref(null);
    const setImageSrc = (value) => {
      imageSrc.value = value;
    };
    const getImageSrc = () => {
      imageSrc.value;
    };
    const setLoaded = (value) => {
      loaded.value = value;
    };

    onUnmounted(() => {
      if (imageSrc.value) imageSrc.value = null;
    });

    const errorLoadImage = (event) => {
      event.target.src = DEFAULT_NO_IMAGE_FOR_PRODUCT;
      emit('errorLoadImage');
      debounce(() => {
        this.setLoaded(true);
      }, 100);
    };
    const imageDidLoaded = () => {
      debounce(() => {
        this.setLoaded(true);
      }, 100);
    };
    return {
      imageSrc,
      setImageSrc,
      getImageSrc,
      setLoaded,
      loaded,
      errorLoadImage,
      imageDidLoaded
    };
  },
  async mounted() {
    const imageSrc = await getImage(this.image, IMAGE_PATH[this.type]);
    this.setImageSrc(imageSrc);
  }
});
</script>

<style lang="scss" scoped>
.product-image::part(image),
.product-image {
  margin-left: 2.5px;
  margin-top: 8px;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  object-fit: cover;
}
.product-image-no-halal::part(image),
.product-image-no-halal {
  margin-left: 2.5px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;
}
.image {
  height: 120px;
  width: 120px;
  position: relative;
  overflow: hidden;
  display: block;
}
.image-no-halal-label {
  height: 120px;
  width: 128px;
}
.image-off-oss {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 120px;
  height: 120px;
  background: #6969692c;
  border-radius: 0px 0px 0px 10px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  box-sizing: border-box;
}
.blend-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 120px;
  height: 120px;
  background: #212121;
  mix-blend-mode: saturation;
  border-radius: 0px 0px 0px 10px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  box-sizing: border-box;
}
.centered-oos-label {
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 10px;
  padding: 4px 8px;
  gap: 10px;
  width: 78px;
  height: 20px;
  background: #212121;
  border-radius: 100px;
}
.oos {
  margin-top: -1px;
}
ion-skeleton-text {
  border-radius: 4px;
}
</style>
